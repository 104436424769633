import React, { useRef, useState, useEffect } from "react";
import StickBar from "../components/StickBar";
import Header from "../components/Header";
import useScrollPosition from "../hooks/useScrollPosition";
import VideoPlayer from "../components/VideoPlayer";
import validator from "validator";
import emailjs from "@emailjs/browser";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import Carousel from "../components/Carousel";
import { SERVER_URL } from "../App";

const Home = () => {
  const scroll = useScrollPosition();
  const [error, setError] = useState("");
  const form = useRef();
  const [contactInformations, setContactInformations] = useState({
    email: "",
  });

  const [showcaseArtworks, setShowcaseArtworks] = useState([]);

  useEffect(() => {
    const getShowcaseArtworks = async () => {
      const docRef = doc(db, "selectedArtworks", "showcaseArtworks");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setShowcaseArtworks(docSnap.data().artworks);
      } else {
        console.log("No such document!");
      }
    };

    getShowcaseArtworks();
  }, []);

  const sendSubscription = (e) => {
    e.preventDefault();

    if (contactInformations.email !== "") {
      if (validator.isEmail(contactInformations.email) === true) {
        fetch(`${SERVER_URL}/api/newsletter`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(contactInformations),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.msg !== "subscribed") {
              emailjs
                .sendForm(
                  "service_j98yodi",
                  "template_v1ohdsx",
                  form.current,
                  "cKc78rMKbgska16W1"
                )
                .then(
                  () => {
                    setError("200");
                  },
                  (error) => {
                    console.log(error.text);
                  }
                );
            } else {
              setError("You are already subscribed to our newsletter.");
            }
          })
          .catch((error) => {
            setError("An error has occurred! please try again.");
          });
      } else {
        setError("Email is invalid.");
      }
    } else {
      setError("Please complete all required fields");
    }
  };

  return (
    <div>
      <Header isVisible={scroll < 100 ? false : true} logo={true} />

      <div className="flex pt-8">
        <StickBar />

        <div className="w-full lg:pl-[110px]">
          <div className="w-full space-y-16 lg:space-y-24 text-white px-6 md:px-8 lg:px-4">
            <section className="space-y-16">
              <div className="flex w-full justify-center">
                <img
                  className="object-cover max-w-[215px] "
                  src="/images/logo.png"
                />
              </div>

              <Carousel data={showcaseArtworks} />

              <div className="flex w-full lg:justify-center">
                <div className="space-y-4 lg:space-y-0 lg:flex lg:space-x-4 lg:items-center">
                  <img
                    className="object-contain max-w-[180px] "
                    src="/images/logo.png"
                  />

                  <h2 className="text-[28px] md:text-[30px] xl:text-[36px]">
                    is a place dedicated to photography and fine digital art.
                  </h2>
                </div>
              </div>
            </section>

            <section className="space-y-8 w-full">
              <div className="space-y-8">
                <div className="lg:flex space-y-4 lg:space-x-4 items-end leading-none">
                  <h2 className="text-[35px] md:text-[40px] lg:text-[44px] font-medium">
                    Artists
                  </h2>

                  <h4 className="text-[28px]">
                    They are our sponsors and the journey starts with them…
                  </h4>
                </div>

                <div className="space-y-6 lg:space-y-0 lg:flex items-center lg:space-x-7">
                  <div className="text-white space-y-6 w-full lg:max-w-[330px]">
                    <h3 className="text-[36px] font-medium">Ahmed Bahhodh</h3>

                    <h4 className="text-[28px]">Kids Fashion Photographer</h4>

                    <h4 className="text-[21px] italic">
                      ”The pursuit of beauty is an inspiration to live life to
                      its fullest. What is beautiful is never limited by the
                      boundaries of esthetics, but instead inspires our minds to
                      explore the depths of beauty and create something entirely
                      new.”
                    </h4>
                  </div>

                  <VideoPlayer
                    path="/videos/Ahmed Bahhodh.mp4"
                    poster="/images/Ahmed-image-video.png"
                  />
                </div>

                <div className="space-y-6 lg:space-y-0 lg:flex items-center lg:space-x-7">
                  <div className="text-white space-y-6 w-full lg:max-w-[330px]">
                    <h3 className="text-[36px] font-medium">Amaury Soares</h3>

                    <h4 className="text-[28px]">
                      Automotive Artist Photographer
                    </h4>

                    <h4 className="text-[21px] italic">
                      “To reveal everything that is outstanding in every
                      legendary car model”
                    </h4>
                  </div>

                  <VideoPlayer
                    path="/videos/amaury-video.mp4"
                    poster="/images/Amaury-image-video.png"
                  />
                </div>
              </div>

              <div className="scrollTo space-y-4" id="newsletter">
                <h3 className="text-[36px] font-medium">Join Us</h3>

                <div className="space-y-3 md:space-y-0 md:flex w-full md:space-x-7">
                  <div className="w-full md:max-w-[330px]">
                    <p className="text-xl">
                      There is no art gallery without great artists and
                      collectors. While we focus on the quality of the art, we
                      strongly promote the need to offer a diverse and original
                      set of creations to feed every soul and heart.
                    </p>
                  </div>

                  <div className="space-y-3 md:space-y-4 w-full">
                    <p className="text-[20px] font-semibold">
                      Register to our Newsletter to stay up to date about
                      TheGallery and key milestones
                    </p>

                    <p className="text-lg">
                      Please provide your email address to subscribe
                    </p>

                    <form
                      ref={form}
                      onSubmit={sendSubscription}
                      className="space-y-3 md:space-y-0 md:flex md:space-x-5 "
                    >
                      <div className="flex py-2 px-7 border-2 border-white rounded-full w-full ">
                        <input
                          type="email"
                          name="user_email"
                          className="outline-none bg-transparent w-full placeholder:text-white text-[17px]"
                          placeholder={"My email address"}
                          onFocus={(e) => (e.target.placeholder = "")}
                          onBlur={(e) =>
                            (e.target.placeholder = "My email address")
                          }
                          onChange={(event) => {
                            setContactInformations({
                              email: event.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className="flex justify-end">
                        <input
                          type="submit"
                          value="Subscribe"
                          className="border-2 border-[#33c7b6] rounded-full text-[#33c7b6] text-[17px] px-7 py-2 cursor-pointer"
                        />
                      </div>
                    </form>

                    {error === "200" ? (
                      <div>
                        <p className="text-sm text-[#33c7b6]">
                          Thank you! Your submission has been sent.
                        </p>
                      </div>
                    ) : (
                      <div>
                        <p className="text-sm text-red-500">{error}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>

            <section className="lg:flex lg:space-x-8 xl:space-x-20 w-full pb-2 lg:pb-16 pr-6 md:pr-8 lg:pr-4">
              <div className="text-white w-full lg:max-w-[400px] xl:max-w-[480px] space-y-4 lg:space-y-12">
                <h1 className="lg:text-[64px]">Ambition</h1>

                <div className="space-y-4 lg:space-y-8">
                  <p className="text-[20px] lg:text-[24px] xl:text-[28px]">
                    Our ambition is to make creator’s artworks available and
                    promoted, while ensuring the value is secured for the
                    artist.
                  </p>

                  <p className="text-[20px] lg:text-[24px] xl:text-[28px]">
                    We believe this is fondamental to create a fair and
                    sustainable ecosystem that will feed our soul and hearts
                    with original creations, beyond time and space.
                  </p>

                  <button>
                    <a
                      className="underline underline-offset-4"
                      href="https://the-gallery.io/about-us"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read more
                    </a>
                  </button>
                </div>
              </div>

              <div className="flex relative w-full items-center py-24 sm:py-32 md:py-36 lg:py-0">
                <img
                  className="object-contain max-w-[68%] md:max-w-[69%] xl:max-w-[68%] z-50"
                  src="/images/amaury-soares-ambition.png"
                />

                <img
                  className="absolute object-contain max-w-[45%] md:max-w-[42%] lg:max-w-[45%] xl:max-w-[45%] max-h-[350px] sm:max-h-[450px] md:max-h-[600px] right-0"
                  src="/images/ahmed-ambition.jpeg"
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
