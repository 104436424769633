import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { TG_PRIVATE_SALE } from "../App";

const BannerHeader = () => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <>
      {isVisible && (
        <div className="flex flex-col md:flex-row z-50 h-[170px] md:h-[100px] bg-[#E9E9E9] md:space-x-10 space-y-6 md:space-y-0 justify-center md:justify-between px-4 md:items-center">
          <p className="text-2xl font-bold text-[#000000]">
            Join the Private Sales - Don’t miss out!
          </p>

          <div className="flex space-x-5 items-center justify-between z-50">
            <a
              href={TG_PRIVATE_SALE}
              target="_blank"
              rel="noreferrer"
              className="px-8 py-4 text-white font-source bg-[#00263A] rounded-full hover:text-[#33c7b6]"
            >
              Join Private Sales
            </a>

            <div className="border border-[#00263A] p-1.5 rounded-full hover:text-[#33c7b6] hover:border-[#33c7b6] cursor-pointer">
              <RxCross2
                className="w-8 h-8"
                onClick={() => {
                  setIsVisible(false);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerHeader;
