import React from "react";
import { Link } from "react-router-dom";

const StickBar = ({ moreStyle }) => {
  const dateYear = new Date().getFullYear();

  return (
    <div
      className={
        "absolute w-[110px] py-4 space-y-[150px] hidden lg:inline " + moreStyle
      }
    >
      <div
        className="flex flex-none items-center text-white space-y-10 w-full"
        style={{ writingMode: "vertical-rl" }}
      >
        <Link
          className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none"
          to="/about-us"
        >
          About us
        </Link>

        <Link
          className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none"
          to="/terms-and-conditions"
        >
          Terms & Conditions
        </Link>

        <Link
          className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none"
          to="/faq"
        >
          FAQ
        </Link>

        <p className="rotate-180 text-lg text-[15px] sm:text-[16px] flex flex-none">
          <span className="rotate-90">©</span> {dateYear} - The Gallery • V1.0.1
        </p>
      </div>

      <div className="flex flex-col text-white space-y-2 items-center">
        <a
          href="https://zealy.io/c/thegalleryio/invite/h4xMRsxqU5oEUnPL88e5E"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Zealy.png" className="w-7 h-7" />
        </a>

        <a
          href="https://twitter.com/TGallery_NFT"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Twitter.png" className="w-7 h-7" />
        </a>

        <a
          href="https://www.instagram.com/the_gallery_nft/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Instagram.png" className="w-7 h-7" />
        </a>

        <a
          href="https://www.facebook.com/TGalleryNFT"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Facebook.png" className="w-7 h-7" />
        </a>

        <a
          href="https://www.linkedin.com/company/thenftgallery/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Linkedin.png" className="w-7 h-7" />
        </a>

        <a
          href="/documents/TheGallery-Whitepaper.pdf"
          target="_blank"
          rel="noreferrer"
          className="flex bg-white rounded-sm  justify-center"
        >
          <img src="/images/Whitepaper.png" className="w-7 h-7" />
        </a>

        <Link to="/faq">
          <img src="/images/FAQ.png" className="w-7 h-7" />
        </Link>
      </div>
    </div>
  );
};

export default StickBar;
